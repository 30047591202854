<template>
  <div class="container-fluid mt-5">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("NOTIFICATIONS.NOTIFICATIONS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right"></div>
        </div>
      </div>

      <notification-list-table />
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import NotificationListTable from "./partials/NotificationListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    NotificationListTable,
    [Button.name]: Button,
  },

  mixins: [],

  computed: {},

  watch: {},

  methods: {},
};
</script>
